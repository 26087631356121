const text = {
    search: "Buscar",
    title: "FAQ (Preguntas y respuestas)",
    link: "enlace"
}

const list1 = {
    "1": "Conexión, equipo necesario, primeros pasos.",
    "1_1": "¿Cómo conectar el sistema Kavapp?",
    "1_1t": "Para trabajar con nuestro sistema, solo tiene que registrarse en nuestro sitio web <0>Kavapp.com</0> y descargar la aplicación <1>Kavapp Seller</1> en su dispositivo. Puede realizar todos los ajustes del sistema Usted mismo o con la ayuda de nuestro soporte técnico.",

    "1_2": "¿Con qué equipo funciona Kavapp?",
    "1_2t1": "Kavapp Admin funciona como un sitio web y se puede acceder desde cualquier navegador en cualquier dispositivo. ",
    "1_2t2": "Kavapp Seller funciona como aplicación móvil en Windows (10+, 64-bit), Android (mínimo 5.1, recomendado 9+) e iOS (mínimo 11, recomendado 15+).",
    "1_2t3": "Kavapp Delivery funciona como aplicación móvil en Android (mínimo 5.1, recomendado 9+) e iOS (mínimo 11, recomendado 15+).",
    "1_2t4": "El programa Kavapp Seller soporta la conexión de impresoras de recibos (Bluetooth, USB, Wi-Fi) y escáneres de códigos de barras (Bluetooth, USB).",

    "1_3": "¿Está el sistema listo para funcionar después de registrarse?",
    "1_3t": "Sí, inmediatamente después de registrarse, obtiene una versión completa del sistema con un período de prueba de 2 semanas gratis en cualquier tarifa. Los artículos básicos y las fichas técnicas están disponibles inmediatamente en el programa, que puede editar y personalizar durante el período de prueba y una vez finalizado. Todos los ajustes que realice durante el período de prueba se guardarán una vez finalizado.  ",
    "1_4": "¿Los empleados de Kavapp configurarán nuestro sistema o instalarán el equipo en la cafetería?",
    "1_4t": "No, solo trabajamos online y no conectamos los equipos en su cafetería, pero si necesita ayuda para conectar o configurar el sistema, puede contactar con nuestro soporte técnico, estaremos encantados de ayudarle.",
    "1_5": "¿Se requiere conexión a Internet para trabajar con el programa?",
    "1_5t": "Se requiere Internet para abrir y cerrar un turno de trabajo en la aplicación Kavapp Seller, si el turno de trabajo ya ha comenzado, la aplicación puede funcionar offline (en este caso, la información de la cuenta personal no se sincronizará y se sincronizará cuando el dispositivo esté conectado a Internet).",
    "1_6": "¿Puedo comprar equipo en Kavapp?",
    "1_6t": "Por el momento, no vendemos ningún tipo de equipos, pero nuestro servicio técnico puede recomendarle equipos específicos.",
}

const list2 = {
    "2": "Acerca de Kavapp.",
    "2_1": "¿Cuánto tiempo lleva funcionando el servicio Kavapp?",
    "2_1t": "Nuestro sistema lleva funcionando desde 2019, trabajamos constantemente para mejorar y ampliar nuestro servicio y no tenemos planes de parar en el futuro.",
    "2_2": "¿Con qué frecuencia se actualiza y mejora el sistema?",
    "2_2t": "Las actualizaciones globales de las aplicaciones se hacen mensualmente, mientras que podemos anunciar actualizaciones menores con mucha más frecuencia, todo ello es necesario para garantizar un funcionamiento estable del sistema y mejorar su funcionalidad. Intentamos escuchar a nuestros clientes para ofrecerles los servicios más cómodos y eficientes posibles.",

    "2_3": "¿Cuáles son las principales ventajas de Kavapp frente a otros sistemas PDV para cafeterías?",
    "2_3t1": "Proporcionamos una funcionalidad eficiente y sencilla a nuestros clientes basándonos en nuestra amplia experiencia en esta área de negocio, por lo que nuestro sistema está enfocado al máximo a trabajar con cafeterías. Hemos superado muchos \"escollos\" habituales en este negocio y ofrecemos soluciones ya preparadas que ayudarán a simplificar, agilizar y mejorar el funcionamiento de su cafetería.",
    "2_3t2": "También consideramos que nuestra ventaja son los bajos precios de nuestros servicios en comparación con nuestros competidores, que actualmente se encuentran entre los más bajos del mercado, y esto se debe al hecho de que nos esforzamos por dar a nuestros clientes el máximo por el mínimo: esta es la política de nuestra empresa.",
    "2_3t3": "Otra diferencia importante con respecto a la mayoría de los demás servicios es la ausencia de restricciones en cuanto al volumen en el sistema: solo en el sistema Kavapp Usted puede crear un número ilimitado de artículos, empleados y fichas técnicas sin coste adicional. ",

    "2_4": "Acuerdo de usuario y política de privacidad de Kavapp.",
    "2_4t1": "Puede leer el acuerdo de usuario que firma al registrarse en nuestro sitio web siguiendo el enlace <0>?????</0>.",
    "2_4t2": "Puede leer la política de privacidad de Kavapp siguiendo el enlace <0>?????</0>.",

    "2_5": "¿Es posible colaborar con Kavapp?",
    "2_5t1": "Sí, pero cada propuesta es considerada individualmente por la gerencia, si tiene una propuesta de colaboración, descríbala y envíela a nuestra dirección de correo electrónico <0>info@kavapp.com</0>. ",
    "2_5t2": "Procesaremos rápidamente su propuesta y le daremos una respuesta lo antes posible.",
}

const list3 = {
    "3": "Pago y tarifas.",
    "3_1": "¿Cómo se hace el pago del sistema?",
    "3_1t": "Una vez finalizado el período de prueba, elija su tarifa y seleccione el período de uso por el que desea pagar (el coste se calcula a base de la tarifa elegida y el número de puntos de venta) y realice el pago a través del servicio LiqPay directamente en la página de su cuenta personal.",

    "3_2": "¿En qué se diferencian las tarifas?",
    "3_2t1": "Nuestras tarifas difieren únicamente en la funcionalidad específica incluida en cada una de ellas.",
    "3_2t2": "La tarifa S es una funcionalidad básica con todos los informes, pero no incluye la posibilidad de calcular el precio de coste de los artículos.",
    "3_2t3": "La tarifa M incluye la funcionalidad de la tarifa S más cálculos financieros (cálculo de precios de coste y pago de compras con efectivo de la caja), sistema de descuentos y creador de promociones.",
    "3_2t4": "La tarifa L incluye la funcionalidad de las tarifas S y M más el creador de posiciones del menú (parámetros y modificadores de bebidas), la posibilidad de vender desde el almacén y de establecer menús y precios diferentes en distintos puntos de venta.",

    "3_3": "¿Puede Kavapp emitir una factura separada para el pago?",
    "3_3t": "No, el pago solo se realiza directamente a través del servicio LiqPay en la página de su cuenta personal de Kavapp.",
    "3_4": "¿Qué formas de pago se aceptan?",
    "3_4t": "Puede realizar un pago con cualquier tarjeta con la que colabora el servicio LiqPay, incluidas las más comunes Visa y MasterCard.",
}

const list4 = {
    "4": "Registro de operaciones contables",
    "4_1": "¿Qué tarifas incluyen registros de operaciones contables y cómo utilizarlos?",
    "4_1t": "El registro de operaciones contables está incluido en todas las tarifas de Kavapp y no requiere pagos adicionales. Las instrucciones paso a paso para la conexión y configuración están disponibles en su cuenta personal después de registrarse en Kavapp."
}


export const faq = {
    ...text,
    ...list1,
    ...list2,
    ...list3,
    ...list4
}