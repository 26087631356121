const components = {
    programForLogisticians: "Программа и рабочее пространство логиста",
    programForSeller: "Программа и рабочее пространство для бариста",
    programForAdmin: "Рабочее пространство администратора",
    header: {
        private_account: "Личный кабинет",
        admin: "Kavapp Admin (Для администратора)",
        sale: "Kavapp Seller (Для продажи)",
        delivery: "Kavapp Delivery (Для логиста)",
    },
    footer: {
        connect: "Наши социальные сети",
        language_change: "Сменить язык",
        we_accept: "Мы принимаем ",
        phone: {
            title: "Наш телефон: ",
            working_days: "пн-пт",
        },
        applicationDeveloper: "Разработчик сайта и системы Kavapp"
    },
    system_requirements: {
        android: "Минимальная версия для Android – 5 (рекомендована 9)",
        ios: "Минимальная версия для iOS – 11 (рекомендуемая 15)",
        windows: "Минимальная версия для Windows – 10, 64-разрядная",
    }
}

export default components