export const pages = {
    about_us: {
        banner: "¡Asistente personal para el dueño de la cafetería!",
        description_first: "Kavapp es un sistema multifuncional e intuitivo para automatizar cafeterías y mini cafeterías.",
        description_second: "Nuestro sistema le dará la oportunidad de establecer y organizar el flujo de trabajo en su empresa por un precio bajo",
        subtitle: "Kavapp: fácil, asequible y rentable",
        advantages: {
            low_price: "Precio bajo en comparación con otros servicios. La posibilidad de elegir la mejor tarifa para su tipo de establecimiento",
            low_price_mini: "Precio bajo",
            pppo: "Trabajar con registro de operaciones contables: abrir turnos de trabajo fiscales y fiscalizar los recibos en la Agencia Tributaria directamente desde el dispositivo de trabajo, según la legislación vigente",
            pppo_mini: "Funcionalidad de registro de operaciones contables",
            tech_card: "Creación de fichas técnicas en cantidades ilimitadas",
            tech_card_mini: "Creación de fichas técnicas",
            wage: "Cálculo de salarios, multas, bonos, pagos anticipados",
            wage_mini: "Cálculo de salario",
            control: "Control de trabajo de baristas, logistas y movimiento de artículos en el almacén",
            control_mini: "Control de la cafetería",
            rediscount: "Posibilidad de realizar inventarios en el punto de venta y en el almacén",
            rediscount_mini: "Función de inventario",
            sale_from_warehouse: "Función de vender artículos desde el almacén",
            sale_from_warehouse_mini: "Venta desde el almacén",
            charts: "Una gran cantidad de gráficos e informes para controlar su negocio",
            charts_mini: "Una gran cantidad de gráficos",
            messenger: "Mensajero sirve para establecer comunicación interna entre baristas, logistas y administradores durante las horas de trabajo, sin distraer del flujo de trabajo",
            messenger_mini: "Mensajero",
            cooking: "Preparación previa de platos/posiciones del menú/ingredientes según la ficha técnica, para su posterior venta o utilización en el punto de venta",
            cooking_mini: "Preparación de platos según las fichas técnicas",
            device: "Alta compatibilidad con varios dispositivos: el sistema Kavapp es compatible con dispositivos Android, iOS y Windows",
            device_mini: "Funciona en Android, iOS y Windows",
        },
        categories: {
            title: "El sistema está dividido en 3 espacios de trabajo",
            admin_title: "- Cuenta personal (Admin)",
            admin_description: " - Espacio de trabajo del administrador",
            seller_title: "- Programa para ventas (Seller)",
            seller_description: " - Espacio de trabajo del barista",
            delivery_title: "- Programa de logística, almacén y entrega (Delivery)",
            delivery_description: " - Espacio de trabajo del logista",
        },
        head: {
            title: 'Kavapp es un sistema para la automatización de cafeterías. Sistema CRM para una cafetería',
            description: 'Automatización de cafeterías con Kavapp. Un sistema para automatizar cafeterías, minicafés y autocafés. Conecte el sistema de automatización de la cafetería online'
        }
    },
    portfolio: {
        portfolio_main: {
            seller: "Programa para barista",
            delivery: "Programa para logista",
            admin: "Programa para administrador",
            head: {
                title: 'Elige un CRM para la automatización de cafeterías Kavapp - automatiza la cafetería',
                description: 'Implementación de un sistema CRM para una cafetería. Cómo elegir un sistema para automatizar una cafetería. Conexión del programa de automatización de la cafetería. Soporte de software PRO'
            }
        },
        portfolio_delivery: {
            description: "Kavapp Delivery es un programa creado específicamente para facilitar y controlar el trabajo de su logista." +
                " Kavapp Delivery sirve para controlar el movimiento de artículos desde el almacén hasta la cafetería, así como" +
                " todo lo relacionado con la logística del almacén. En el sistema, el logista recibe los artículos en el almacén," +
                " utilizando el programa, el empleado registra la cantidad de artículos llevada a la cafetería desde el almacén," +
                " sabe qué cantidad de artículos se utilizó para preparar las bebidas y así puede ver la cantidad de artículos que se queda en la cafetería" +
                " en tiempo real a través del programa Kavapp Delivery (una funcionalidad similar está disponible en el espacio de trabajo del administrador)",
            post_description: "Kavapp Delivery: la logística de su negocio",
            description_slides: {
                first_slide: "Página principal Delivery: en esta página, el logista puede utilizar el mensajero, aceptar" +
                    " pedidos del barista, confirmar deducciones/cobros y compras en puntos de venta",
                second_slide: 'El menú "Almacén": contiene todo lo relacionado con la logística del almacén: disponibilidad, inventario, llegadas ' +
                    'e incluso ventas desde el almacén. Es posible generar informes sobre el movimiento de artículos',
                three_slide: "Pantalla de creación de pedidos: cree y envíe cómodamente pedidos mediante la aplicación Kavapp Delivery",
                fourth_slide: 'Menú "Puntos de venta": aquí el logista puede comprobar la disponibilidad de los artículos en los puntos de venta, hacer' +
                    ' un inventario en el punto de venta y ver el movimiento de los artículos',
                fifth_slide: "Pantalla de ajustes Delivery: todos los ajustes relacionados con la aplicación en un solo lugar. " +
                    "Cambio rápido del tema de la aplicación, configuración de la seguridad, gestión de las notificaciones y cambio del perfil del empleado",
            },
            head: {
                title: 'Programa de control de residuos en una cafetería. Controla la logística con Kavapp',
                description: 'Kavapp Delivery es un programa y espacio de trabajo de un logístico. Fácil control de saldos y movimiento de mercancías en la cafetería. Facilitando el trabajo del logístico con Kavapp'
            }
        },
        portfolio_seller: {
            description: "Kavapp Seller es la parte del sistema Kavapp a través de la cual se realizan directamente todas las ventas de іu cafetería." +
                " Ventas cómodas y rápidas, posibilidad de crear un pedido de artículos, cobros, compras para el punto de venta, " +
                "deducciones y todo tipo de funciones que sus empleados necesitarán. Controle todas las ventas " +
                "y tiempo de trabajo del barista gracias a Kavapp Seller.",
            post_description: "Kavapp Seller: cómodo, rápido y fiable.",
            link_apk: {
                description_one: "Descarga alternativa en Android",
                description_two: ", en Windows ",
                link: "aquí",
            },
            description_slides: {
                first_slide: "La pantalla principal es el principal lugar de trabajo del barista en Seller. En esta pantalla, el barista seleccionalos artículos" +
                    " que hay que añadir a la cuenta. Si es necesario, el barista puede utilizar la búsqueda para encontrar rápidamente" +
                    " el artículo deseado y retener el recibo (manteniendo pulsado el botón de venta durante 2 segundos)",
                second_slide: "Pantalla de cálculo. En esta pantalla, el barista indica el método de pago y aplica un descuento si es necesario " +
                    "y calcula el cambio que hay que entregar al cliente.",
                three_slide: "Pantalla de funciones. En esta pantalla el barista puede crear pedidos/compras/deducciones y cobros" +
                    " en el punto de venta. Además, el empleado puede ver la disponibilidad del producto en el punto de venta, " +
                    "registrar una tarjeta de bonificación para un cliente y ver todos los estados financieros del turno de trabajo.",
                fourth_slide: "Pantalla de recibos vendidos. Aquí el barista puede ver el historial completo de ventas durante el turno de trabajo actual, " +
                    "crear un recibo de devolución o cancelar el último recibo vendido.",
                fifth_slide: "Pantalla de configuración. En este menú puede ver y cambiar todas las configuraciones relacionadas" +
                    " con la aplicación Seller, sincronizar datos con el panel de administrador y conectar una impresora de recibos",
            },
            head: {
                title: 'Un programa conveniente para automatizar una cafetería. Ventas cómodas y rápidas.',
                description: 'Kavapp Seller: aplicación y espacio de trabajo para baristas. Interfaz intuitiva para empleados, fácil de dominar y trabajar.'
            }
        },
        portfolio_admin: {
            description: 'Kavapp Admin es la parte principal del servicio Kavapp. En este espacio de trabajo, Usted realiza todos' +
                ' los ajustes necesarios del sistema y los puntos de venta, crea menú, precios, fichas técnicas. Una gran cantidad de gráficos' +
                ' e informes le ayudará a analizar con rapidez y precisión su negocio. En Admin, Usted también puede añadir a todos sus' +
                ' empleados, configurar el sistema de descuentos, calcular los salarios y, si es necesario, confirmar ' +
                'pedidos/deducciones/cobros, etc.',
            post_description: 'Kavapp Admin es una poderosa herramienta en manos de un administrador',
            description_slides: {
                first_slide: 'La página principal de la cuenta personal da estadísticas breves sobre los turnos de trabajo actuales, información general' +
                    ' y gráficos de venta en sus puntos de venta.',
                second_slide: 'Menú "Puntos de venta". Cree sus puntos de venta, configure el menú, los precios, los salarios y la funcionalidad de cada punto.',
                three_slide: 'Menú "Empleados". En esta pantalla, Usted puede añadir a los empleados que trabajan en sus puntos de venta' +
                    ' a través del programa Kavapp Seller y especificar sus salarios (puede especificar diferentes salarios' +
                    ' para cada empleado por separado).',
                fourth_slide: 'Menú "Logístas". Es una pantalla en la que se añaden los logistas que trabajarán en la aplicación ' +
                    'Kavapp Delivery (aquí puede obtener el código de conexión del logista)',
                fifth_slide: 'Menú "Ingredientes". Es la pantalla en la que se crean todos los ingredientes, a partir de los cuales se forman ' +
                    ' después las fichas técnicas de sus bebidas y productos.',
                sixth_slide: 'Menú "Bebidas". Es un menú de todas las bebidas que se venden en su punto de venta. Aquí puede elegir ' +
                    'los utensilios que se utilizan para cocinar, especificar el precio de la bebida y los ingredientes que incluye.',
                seventh_slide: 'Menú "Productos". Es la pantalla donde se crean todos los productos que se venderán en sus' +
                    ' puntos de venta (estas posiciones del menú se componen de ingredientes que se deducen en el momento de la venta).',
                eighth_slide: 'Menú "Artículos". Incluye los artículos que se venden en sus puntos de venta, los artículos pueden ser vendios ' +
                    ' por unidades o por peso (estos artículos no constan de ingredientes).',
                ninth_slide: 'Menú "Inventario". Es una pantalla en la que se puede realizar un inventario en el punto de venta, indicando' +
                    ' los datos reales de disponibilidad de artículos, ingredientes y envase.',
                tenth_slide: 'Menú "Descuentos". Es una pantalla donde se crean todos descuentos que se pueden utilizar' +
                    ' para las tarjetas personales de sus clientes habituales.',
            },
            head: {
                title: 'El espacio de trabajo del administrador de la cafetería. CRM para la automatización de cafeterías',
                description: 'Kavapp Admin: el espacio de trabajo del administrador. Automatización de la gestión de cafeterías: mapas tecnológicos, menús, cálculo de salarios, saldos.'
            }
        },
    },
    tariffs: {
        banner: {
            main_title: {
                strong: "Los mejores ",
                light: "precios para el sistema de gestión de cafeterías",
            },
            secondary_title: {
                strong: "A partir de",
                currency: "UAH/mes",
                currency_usd: "USD/mes",
                for_shop: "por una cafetería"
            },
        },

        card_title_currency: {
            uk: "UAH/mes",
            usd: "USD/mes"
        },
        card_title_every_point: "por cada punto de venta",
        button: {
            open_button: "Leer más",
            close_button: "Minimizar",
        },
        priceSelect: "Precio al pagar por: ",
        period: {
            "1": "1 mes",
            "3": "3 meses",
            "6": "6 meses",
            "12": "12 meses",
        },
        head: {
            title: 'Tarifas del sistema de automatización de cafeterías Kavapp',
            description: 'Precios de CRM para automatización de cafeterías. Programa económico para la automatización de cafeterías. Los mejores precios para el sistema de software de gestión de cafeterías.'
        }
    },
    contact: {
        left_block: {
            title: "Contacte con nosotros",
            main_description: "Si tiene preguntas urgentes o problemas para trabajar con el programa, puede ponerse en contacto" +
                " con nosotros a través de:",
            phone: "Teléfono:",
            email: "Correo electrónico:",
            work_schedule: "Horario de trabajo:",
        },
        right_block: {
            title: "Escribir un correo",
            name: "Nombre",
            mail: "Número de teléfono o correo electrónico",
            mail_usd: "Correo electrónico",
            mail_mini: "Teléfono o correo electrónico",
            mail_mini_usd: "Correo electrónico",
            message: "Su pregunta o mensaje",
            error_message: "Datos no especificados",
            error_contact: "Datos admisibles no especificados",
            sent_message: "¡Su mensaje ha sido enviado!",
            no_message_sent: "¡Su mensaje no ha sido enviado! ¡Intente enviarlo de nuevo!",
        },
        head: {
            title: 'CRM Kavapp: contáctenos para la automatización de cafeterías',
            description: 'Conecte el programa Kavapp: contáctenos para el cálculo y la conexión. La automatización de cafeterías es sencilla y económica con Kavapp'
        }
    },
}

export default pages

export const tariff_list = [
    "Cantidad ilimitada de fichas técnicas",
    "Salarios, pagos anticipados, multas",
    "Almacén y logística",
    "Ajustes de seguridad",
    "Mensajero integrado",
    "Preparación previa",
    "Sistema de descuentos",
    "Constructor de promociones",
    "Cálculos del precio de coste",
    "Modificadores de bebidas",
    "Venta desde el almacén",
    "Diferentes precios en las cafeterías"
]

export const add_tariff_list = [
    "Informes básicos",
    "Informes completos",
    "Registro de operaciones contables",
]