export const pages = {
    about_us: {
        banner: "Особистий помічник для власника кав'ярні!",
        description_first: "Kavapp – багатофункціональна та зрозуміла система для автоматизації кав'ярень, мінікав'ярень, а також автокав'ярень.",
        description_second: "Наша система дасть вам можливість за невисоку ціну налаштувати та організувати робочий процес у своєму бізнесі.",
        subtitle: "Kavapp – легко, доступно, вигідно!",
        advantages: {
            low_price: "Низька ціна, в порівнянні з іншими сервісами. Можливість обрати оптимальний тариф для Вашого типу закладу",
            low_price_mini: "Низька ціна",
            pppo: "Робота з пРРО - відкривайте фіскальні зміни та фіскалізуйте продані чеки в податковій прямо з робочого пристрою, відповідно до чинного законодавства",
            pppo_mini: "Підтримка пРРО",
            tech_card: "Створення технологічних карток в необмеженій кількості ",
            tech_card_mini: "Створення тех. карток",
            wage: "Розрахунок зарплати, нарахування штрафів, премій, авансів",
            wage_mini: "Розрахунок зарплати",
            control: "Контроль роботи бариста, логіста та рух товару на складі",
            control_mini: "Контроль роботи кав'ярні",
            rediscount: "Можливість проводити переобліки на торгових точках та на складі",
            rediscount_mini: "Функція переобліку",
            sale_from_warehouse: "Функція продажу товару зі складу",
            sale_from_warehouse_mini: "Продаж зі складу",
            charts: "Велика кількість графіків та звітів, для контролю вашого бізнесу",
            charts_mini: "Велика кількість графіків",
            messenger: "Месенджер - внутрішнє листування між бариста, логістами та адміністраторами в робочий час, не відволікаючись від робочого процесу",
            messenger_mini: "Месенджер",
            cooking: "Попереднє приготування страв/позицій меню/інгредієнтів по технологічні карті, для подальших продажів або використання на торговій точці",
            cooking_mini: "Приготування страв по тех. карті",
            device: "Висока сумісність програми з різними пристроями - система Kavapp підтримує пристрої на ОС Android, iOS та Windows",
            device_mini: "Працює на  Android, iOS, Windows",
        },
        categories: {
            title: "Система розділена на 3 робочих простори",
            admin_title: "- Особистий кабінет (Admin)",
            admin_description: " - робочий простір адміністратора",
            seller_title: "- Програма для продажу (Seller)",
            seller_description: " - робочий простір бариста",
            delivery_title: "- Логістична програма, склад і доставка (Delivery)",
            delivery_description: " - робочий простір логіста",
        },
        head: {
            title: 'Kavapp – система для автоматизації кав\'ярні. CRM система для кав\'ярні',
            description: 'Автоматизація кав\'ярні з Kavapp. Система для автоматизації кав\'ярень, мінікав\'ярень та автокав\'ярень. Підключити систему для автоматизації кав\'ярні онлайн'
        }
    },
    portfolio: {
        portfolio_main: {
            seller: "Програма для бариста",
            delivery: "Програма для логіста",
            admin: "Програма для адміністратора",
            head: {
                title: 'Обрати CRM для автоматизації кав\'ярні. Kavapp - автоматизуй кав\'ярню',
                description: 'Впровадження CRM системи для кав\'ярні. Як обрати систему для автоматизації кав\'ярні. Підключення програми для автоматизації кав\'ярні.  Підтримка програмного РРО'
            },
        },
        portfolio_delivery: {
            description: "Kavapp Delivery - програма створена спеціально для полегшення та контролю роботи Вашого логіста. " +
                "Kavapp Delivery призначена для контролю руху товарів від складу до кав'ярні, а також усього, " +
                "що стосується складської логістики. В комплексі роботи всієї системи, логіст оприбутковує товар на склад," +
                " зі складу за допомогою програми, працівник фіксує кількість привезеного товару на кав'ярню, " +
                "знає скільки товару  пішло на приготування напоїв – відповідно може бачити кількість товару, що залишилась на кав'ярні " +
                "в режимі реального часу через програму Kavapp Delivery (аналогічний функціонал є у робочому просторі адміністратора).",
            post_description: "Kavapp Delivery - логістика Вашого бізнесу.",
            description_slides: {
                first_slide: "Головна сторінка Delivery - на цій сторінці логіст може користуватись месенджером, приймати" +
                    " замовлення від бариста, підтверджувати списання/інкасації та закупівлі на торгових точках.",
                second_slide: 'Меню "Склад" - все, що стосується логістики складу - наявність, переобліки, оприбуткування ' +
                    'та навіть продажі зі складу. Присутня можливість формувати звіти по руху товарів.',
                three_slide: "Екран формування замовлення - зручно формуйте та відвантажуйте замовлення за допомогою застосунку Kavapp Delivery",
                fourth_slide: 'Меню "Точки продажу" - тут логіст може перевіряти наявність товару на торгових точках, зробити' +
                    ' переоблік на точці та переглянути рух товару',
                fifth_slide: "Екран налаштувань Delivery - усі налаштування, що стосуються додатку в одному місці. Швидка " +
                    "зміна теми додатку до вподоби, налаштування безпеки, керування сповіщеннями та зміна профілю працівника.",
            },
            head: {
                title: 'Програма контролю залишків в кав\'ярні. Контролюй логістику з Kavapp',
                description: 'Kavapp Delivery - програма та робочий простір логіста. Легкий контроль залишків та переміщення товарів в кав\'ярні. Полегшення роботи логіста з Kavapp'
            }
        },
        portfolio_seller: {
            description: "Kavapp Seller - частина системи Kavapp, через яку безпосередньо проводяться усі продажі з Вашої кав'ярні. " +
                "Зручні та швидкі продажі, можливість створювати замовлення товару, інкасація готівки, закупівлі товару на точку, " +
                "будь-які списання та багато іншого функціоналу, який стане у нагоді Вашому працівнику. Контролюйте всі продажі" +
                " та робочий час бариста завдяки програмі Kavapp Seller.",
            post_description: "Kavapp Seller - зручно, швидко та надійно.",
            link_apk: {
                description_one: "Альтернативне завантаження на Android ",
                description_two: ", на Windows ",
                link: "тут",
            },
            description_slides: {
                first_slide: "Головний екран - основне робоче місце бариста у Seller. На цьому екрані бариста обирає позиції" +
                    " які потрібно додати у чек. За потреби, бариста може використовувати пошук, щоб швидко знайти" +
                    " потрібний товар, та відкласти чек (затримавши кнопку продати на 2 секунди)",
                second_slide: "Екран розрахунку. На цьому екрані бариста вказує спосіб оплати, за потреби проводить знижку " +
                    "та розраховує решту, яку потрібно віддати клієнту.",
                three_slide: "Екран функцій - на цьому екрані бариста може створювати замовлення/закупівлі/списання та інкасації" +
                    " на торговій точці. Також працівник може переглянути наявність товару на торговій точці, " +
                    "зареєструвати бонусну картку для клієнта та переглянути всю фінансову звітність за робочу зміну.",
                fourth_slide: "Екран проданих чеків - тут бариста може переглянути всю історію проданих за поточну робочу зміну " +
                    "чеків, створити чек повернення або відмінити останній проданий чек.",
                fifth_slide: "Екран налаштувань - у цьому меню Ви можете переглянути та змінити всі налаштування, що стосуються" +
                    " додатку Seller, синхронізувати дані з адмінкою та підключити принтер чеків.",
            },
            head: {
                title: 'Зручна програма для автоматизації кав\'ярні. Зручні та швидкі продажі',
                description: 'Kavapp Seller - програма та робочий простір для бариста. Інтуітивно зрозумілий інтерфейс для працівників, легкий в освоюванні та в роботі'
            }
        },
        portfolio_admin: {
            description: 'Kavapp Admin - головна частина сервісу Kavapp. У цьому робочому просторі Ви проводите усі необхідні' +
                ' налаштування системи та торгових точок, створюєте меню, ціни, тех карти. Велика кількість графіків' +
                ' та звітів, допоможе Вам швидко та точно аналізувати Ваш бізнес. В Admin Ви також додаєте всіх своїх' +
                ' працівників, налаштовуєте дисконтну систему, нараховуєте зарплати, та, за потреби, підтверджуєте ' +
                'замовлення/списання/інкасації тощо. ',
            post_description: 'Kavapp Admin - потужній інструмент в руках адміністратора',
            description_slides: {
                first_slide: 'Головна сторінка особистого кабінету - коротка статистика по поточних робочих змінах, загальні' +
                    ' відомості та графіки з продажу на Ваших торгових точках.',
                second_slide: 'Меню "Точки продажу" - створюйте свої торгові точки, налаштовуйте меню, ціни, заробітну плату та функціонал на кожній точці.',
                three_slide: 'Меню "Працівники" - на цьому екрані Ви додаєте персонал, який працює на Ваших торгових точках' +
                    ' через програму Kavapp Seller та вказуєте їх заробітну плату - можна вказувати різну заробітну' +
                    ' плату для кожного працівника окремо.',
                fourth_slide: 'Меню "Логісти" - екран, на якому Ви додаєте Ваших логістів, які будуть працювати у додатку ' +
                    'Kavapp Delivery (тут Ви можете отримати код підключення логіста).',
                fifth_slide: 'Меню "Інгредієнти" - екран на якому Ви створюєте всі інгредієнти, з яких потім формуються ' +
                    'тех.картки Ваших напоїв та продуктів.',
                sixth_slide: 'Меню "Напої" - це меню усіх напоїв, що продаються на Вашій торговій точці. Тут Ви обираєте ' +
                    'посуд, який використовується для приготування, вказуєте ціну напою та інгредієнти, що входять до нього.',
                seventh_slide: 'Меню "Продукти" - екран, на якому Ви створюєте усі продукти, які будуть продаватись на Ваших' +
                    ' торгових точках (ці позиції складаються з інгредієнтів, що списуються при продажі).',
                eighth_slide: 'Меню "Товари" - це товари, що продаються на Ваших торгових точках, товар може бути як ' +
                    'поштучним, так і на вагу (ці позиції не складаються з інгредієнтів).',
                ninth_slide: 'Меню "Переоблік" - екран на якому Ви можете провести переоблік на торговій точці, вказавши' +
                    ' фактичні показники наявності товарів, інгредієнтів та посуду.',
                tenth_slide: 'Меню "Знижки" - екран, на якому Ви створюєте всі види знижок, які можуть використовуватись' +
                    ' для персональних карт Ваших постійних клієнтів.',
            },
            head: {
                title: 'Робочий простір адміністратора кав\'ярні. CRM для автоматизації кав\'ярні',
                description: 'Kavapp Admin - робочий простір адміністратора. Автоматизація ведення кав\'ярні: технологічні карти, меню, нарахування заробітньої плати, залишки'
            }
        },
    },

    tariffs: {
        banner: {
            main_title: {
                strong: "Найвигідніші ",
                light: "ціни системи програм ведення кав'ярні",
            },
            secondary_title: {
                strong: "Від",
                currency: "грн/місяць",
                currency_usd: "USD/місяць",
                for_shop: "за кав'ярню"
            },
        },

        card_title_currency: {
            uk: "грн/місяць",
            usd: "USD/місяць",
        },
        card_title_every_point: "за торгову точку",
        button: {
            open_button: "Докладніше",
            close_button: "Звернути",
        },
        priceSelect: "Ціна у разі оплати за: ",
        period: {
            "1": "1 місяць",
            "3": "3 місяці",
            "6": "6 місяців",
            "12": "12 місяців",
        },
        head: {
            title: 'Тарифи на систему автоматизації для кав\'ярні Kavapp',
            description: 'Ціни на CRM для автоматизації кав\'ярні. Недорога програма для автоматизації кав\'ярні. Найвигідніші ціни системи програм ведення кав\'ярні'
        }
    },
    contact: {
        left_block: {
            title: "Зв'язатися з нами",
            main_description: "Якщо у вас виникли термінові питання до нас чи проблеми в роботі з програмою, - ви можете зв'язатись" +
                " з нами в зручний для вас спосіб.",
            phone: "Телефон:",
            email: "Електронна пошта:",
            work_schedule: "Графік роботи:",
        },
        right_block: {
            title: "Написати лист",
            name: "Ім'я",
            mail: "Номер телефону або електронна пошта",
            mail_usd: "Електронна пошта",
            mail_mini: "Телефон або e-mail",
            mail_mini_usd: "E-mail",
            message: "Ваше питання або повідомлення",
            error_message: "Не вказано дані",
            error_contact: "Не вказано допустимі дані",
            sent_message: "Ваше повідомлення відправлено!",
            no_message_sent: "Ваше повідомлення не відправлено! Спробуйте надіслати ще раз!",
        },
        head: {
            title: 'CRM Kavapp - зв\'яжіться з нами для автоматизації кав\'ярні',
            description: 'Підключити програму Kavapp - зв\'язок з нами для розрахунку та підключення. Автоматизація кав\'ярні - це просто та недорого разом з Kavapp\n'
        }
    },
}

export default pages

export const tariff_list = [
    "Необмежена кількість тех. карт",
    "Зарплата, аванси, штрафи",
    "Склад і логістика",
    "Налаштування безпеки",
    "Вбудований месенджер",
    "Попереднє приготування",
    "Дисконтна система",
    "Конструктор акцій",
    "Підрахунки собівартості",
    "Модифікатори напоїв",
    "Продаж зі складу",
    "Різні ціни на кав'ярнях"
]

export const add_tariff_list = [
    "Базова звітність",
    "Повна звітність",
    "ПРРО",
]