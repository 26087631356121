const components = {
    programForLogisticians: "The program and workspace of the logistician",
    programForSeller: "Program and workspace for a barista",
    programForAdmin: "Administrator workspace",
    header: {
        private_account: "Private account",
        admin: "Kavapp Admin (For admin)",
        sale: "Kavapp Seller (For sale)",
        delivery: "Kavapp Delivery (For the logistician)",
    },
    footer: {
        connect: "Our social networks",
        language_change: "Change language",
        we_accept: "We accept ",
        phone: {
            title: "Phone: ",
            working_days: "mon-fri",
        },
        applicationDeveloper: "Developer of the website and Kavapp system"
    },
    system_requirements: {
        android: "The minimum version for Android - 5 (9 is recommended)",
        ios: "The minimum version for iOS - 11 (15 is recommended)",
        windows: "The minimum version for Windows - 10, 64-bit"
    }
}

export default components