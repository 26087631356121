const components = {
    programForLogisticians: "El programa y el espacio de trabajo del logista",
    programForSeller: "El programa y el espacio de trabajo del barista",
    programForAdmin: "El espacio de trabajo del administrador",
    header: {
        private_account: "Cuenta personal",
        admin: "Kavapp Admin (Para el administrador)",
        sale: "Kavapp Seller (Para ventas)",
        delivery: "Kavapp Delivery (Para el logista)",
    },
    footer: {
        connect: "Nuestras redes sociales",
        language_change: "Cambiar idioma",
        we_accept: "Aceptamos ",
        phone: {
            title: "Teléfono: ",
            working_days: "lunes-viernes",
        },
        applicationDeveloper: "Desarrollador del sitio web y del sistema Kavapp"
    },
    system_requirements: {
        android: "La versión mínima de Android: 5 (se recomienda 9)",
        ios: "La versión mínima de iOS: 11 (se recomienda 15)",
        windows: "La versión mínima de Windows: 10, 64-bit"
    }
}

export default components