const text = {
    search: "Поиск",
    title: "FAQ (Вопросы и ответы)",
    link: "ссылке"
}

const list1 = {
    "1": "Подключение, необходимое оборудование, начало работы.",
    "1_1": "Как подключить систему Kavapp?",
    "1_1t": "Для работы с нашей системой Вам достаточно зарегистрироваться на нашем сайте <0>Kavapp.com</0> и скачать приложение <1>Kavapp Seller</1> на рабочее устройство. Все настройки системы Вы можете провести самостоятельно или с помощью нашей тех.поддержки.",

    "1_2": "С каким оборудованием работает Kavapp?",
    "1_2t1": "Kavapp Admin работает в формате веб-сайта и может быть открыт из любого браузера на любом устройстве. ",
    "1_2t2": "Kavapp Seller работает в формате мобильного приложения на операционных системах Windows (10+, 64-разрядная), Android (минимальный 5.1, рекомендованный 9+) и iOS (минимальный 11, рекомендованный 15+).",
    "1_2t3": "Kavapp Delivery работает в формате мобильного приложения на Android (минимальный 5.1, рекомендованный 9+) и iOS (минимальный 11, рекомендованный 15+).",
    "1_2t4": "Программа Kavapp Seller поддерживает подключение принтеров чеков (Bluetooth, USB, Wi-Fi) и сканер штрих-кодов (Bluetooth, USB).",

    "1_3": "Готова ли система к работе после регистрации?",
    "1_3t": "Да, сразу после регистрации Вы получаете полноценную версию системы с пробным периодом 2 недели бесплатно на любом тарифе. В программе сразу присутствуют стандартные товары и тех. карты, которые Вы можете редактировать и настраивать в течение пробного периода и после его завершения. Все настройки которые Вы проведете во время пробного периода сохранятся после его завершения.  ",
    "1_4": "Настраивают ли работники Kavapp нашу систему, устанавливают ли оборудование на кофейне?",
    "1_4t": "Нет, мы работаем только в формате он-лайн и не подключаем оборудование в Вашей кофейне, однако, если Вам нужна помощь в подключении или настройке системы, Вы можете обратиться в нашу тех.поддержку, будем рады помочь.",
    "1_5": "Нужен ли интернет для работы с программой?",
    "1_5t": "Интернет нужен для открытия и закрытия рабочей смены в программе Kavapp Seller, если смена уже была начата, приложение может работать офлайн (при этом информация в личном кабинете не будет синхронизирована и синхронизируется при подключении устройства к интернету).",
    "1_6": "Можно ли приобрести у Kavapp оборудование?",
    "1_6t": "На данный момент мы не продаем никакого оборудования, но Вы можете получить рекомендации по определенному оборудованию у нашей тех.поддержки.",
}

const list2 = {
    "2": "О компании Kavapp.",
    "2_1": "Как давно работает сервис Kavapp?",
    "2_1t": " Наша система работает начиная с 2019 года, мы постоянно работаем над улучшением и расширением нашего сервиса и не планируем останавливаться в будущем.",
    "2_2": "Как часто обновляется и улучшается система?",
    "2_2t": "Глобальные обновления приложений выходят ежемесячно, при этом мы можем выпускать меньшие правки значительно чаще, все это необходимо для обеспечения стабильной работы системы и улучшения функционала. Мы стараемся прислушиваться к нашим клиентам чтобы обеспечивать максимально комфортные и эффективные услуги. ",

    "2_3": "В чем главные преимущества Kavapp перед другими POS-системами для кофейни?",
    "2_3t1": "Мы предоставляем эффективный и простой функционал для наших клиентов руководствуясь большим опытом в данной сфере ведения бизнеса, поэтому наша система максимально ориентирована для работы именно с кофейнями. Мы проработали много \"подводных камней\", которые встречаются в таком бизнесе и предоставляем готовые решения, которые помогут упростить, ускорить и улучшить работу Вашей кофейни.",
    "2_3t2": "Также своим преимуществом мы считаем низкие цены на наши услуги по сравнению с конкурентами, на сегодня - они одни из самых низких на рынке и это связано с тем, что мы стремимся дать нашим клиентам максимум за минимум - это политика нашей компании. ",
    "2_3t3": "Еще одно важное отличие от большинства других сервисов - отсутствие каких-либо ограничений по объемам в системе: только в системе Kavapp Вы можете создавать неограниченное количество товаров, персонала и тех.карт без дополнительных затрат.  ",

    "2_4": "Пользовательское соглашение и политика конфиденциальности Kavapp.",
    "2_4t1": "Ознакомиться с пользовательским соглашением, которое Вы подписываете при регистрации на нашем сайте Вы можете по <0>?????</0>.",
    "2_4t2": "Ознакомиться с политикой конфиденциальности Kavapp Вы можете по <0>?????</0>.",

    "2_5": "Возможно ли сотрудничество с компанией Kavapp?",
    "2_5t1": "Да, но каждое предложение рассматривается руководством индивидуально, если Вы имеете предложение о сотрудничестве, пожалуйста опишите его и отправьте на наш электронный адрес <0>info@kavapp.com</0>.",
    "2_5t2": "Мы быстро обработаем Ваше предложение и предоставим ответ в кратчайшие сроки.",
}

const list3 = {
    "3": "Оплата и тарифы.",
    "3_1": "Как происходит оплата системы?",
    "3_1t": " После того как у Вас заканчивается пробный период, Вы выбираете свой тариф и выбираете срок пользования, который хотите оплатить (стоимость считается исходя из выбранного тарифа и количества торговых точек) и проводите платеж через сервис LiqPay прямо на странице личного кабинета.",

    "3_2": " Чем отличаются тарифы?",
    "3_2t1": "Наши тарифные планы отличаются только определенным функционалом, который входит в каждый из этих тарифов.",
    "3_2t2": "Тариф S - это базовый функционал со всей отчетностью, не имеет в себе возможности рассчитывать себестоимость позиций.",
    "3_2t3": "Тариф M - это функционал тарифа S + финансовые расчеты (подсчеты себестоимости и расчет по закупкам средствами из кассы), дисконтная система и конструктор акций.",
    "3_2t4": "Тариф L - это функционал тарифов S и M + конструктор позиций меню (параметры и модификаторы напитков), возможность вести продажи со склада и устанавливать разные меню и цены на разных торговых точках.",

    "3_3": " Может ли Kavapp выставить отдельный счет на оплату?",
    "3_3t": "Нет, оплата производится только напрямую через сервис LiqPay на странице Вашего личного кабинета Kavapp.",
    "3_4": "Какие типы оплаты принимаются?",
    "3_4t": "Вы можете произвести оплату с любой карты с которой работает сервис LiqPay, в том числе самые распространенные Visa и MasterCard.",
}

const list4 = {
    "4": "пРРО",
    "4_1": "В какие тарифы входит пРРО и как его подключить и настроить?",
    "4_1t": "пРРО входит во все тарифы Kavapp без дополнительных доплат. Пошаговые инструкции по настройке и подключению Вы получите в своём личном кабинете после регистрации на нашем сайте"
}


export const faq = {
    ...text,
    ...list1,
    ...list2,
    ...list3,
    ...list4
}