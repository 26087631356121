const components = {
    programForLogisticians: "Програма та робочий простір логіста",
    programForSeller: "Програма та робочий простір для бариста",
    programForAdmin: "Робочий простір адміністратора",
    header: {
        private_account: "Особистий кабінет",
        admin: "Kavapp Admin (Для адміністратора)",
        sale: "Kavapp Seller (Для продажу)",
        delivery: "Kavapp Delivery (Для логіста)",
    },
    footer: {
        connect: "Наші соціальні мережі ",
        language_change: "Змінити мову",
        we_accept: "Ми приймаємо ",
        phone: {
            title: "Наш телефон: ",
            working_days: "пн-пт",
        },
        applicationDeveloper: "Розробник сайту та системи Kavapp"
    },
    system_requirements: {
        android: "Мінімальна версія для Android - 5 (рекомендована 9)",
        ios: "Мінімальна версія для iOS - 11 (рекомендована 15)",
        windows: "Мінімальна версія для Windows - 10, 64-розрядна",
    }
}

export default components